// Here you can add other styles

//Font-Size
.cfs-6 {
    font-size: 1rem;
}
.cfs-8 {
    font-size: 1.5rem;
}
.cfs-10 {
    font-size: 2rem;
}
.cfs-12 {
    font-size: 2.5rem;
}
.cfs-14 {
    font-size: 3rem;
}

//Font-Weight

.cfw-1 {
    font-weight: 100;
}
.cfw-2 {
    font-weight: 200;
}
.cfw-3 {
    font-weight: 300;
}
.cfw-4 {
    font-weight: 400;
}
.cfw-5 {
    font-weight: 500;
}
.cfw-6 {
    font-weight: 600;
}
.cfw-7 {
    font-weight: 700;
}


//Margin
.cmx-1 {
    margin: 0rem 1rem;
}
.cmx-2 {
    margin: 0rem 1.5rem;
}
.cmx-3 {
    margin: 0rem 2rem;
}
.cmx-4 {
    margin: 0rem 2.5rem;
}
.cmx-5 {
    margin: 0rem 3rem;
}
.cmx-6 {
    margin: 0rem 3.5rem;
}
.cmx-7 {
    margin: 0rem 4rem;
}
.cmx-8 {
    margin: 0rem 4.5rem;
}
.cmx-9 {
    margin: 0rem 5rem;
}
.cmx-10 {
    margin: 0rem 5.5rem;
}
.cmx-11 {
    margin: 0rem 6rem;
}
.cmx-12 {
    margin: 0rem 6.5rem;
}
.cmx-13 {
    margin: 0rem 7rem;
}
.cmx-14 {
    margin: 0rem 7.5rem;
}
.cmx-15 {
    margin: 0rem 8rem;
}

//LOGIN CSS
#bg-primary {
    background-image: url('./../assets/images/bg2.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.login-box {
    width: 20rem;
    height: 32rem;
    box-sizing: content-box;
    box-shadow: 2px 11px 60px 0px rgba(0,0,0,0.53);
    border-radius: 1rem !important;
}

.login-button {
    border-radius: 0.5rem !important;
    width: 100%;
}

.login-logo {
    position: absolute;
    margin-top: 2rem;
    margin-left: 1rem;
}

hr.solid {
    border-top: 2px solid #999;
}

.form-container {
    justify-content: end;
    margin-right: 5%;
}

@media (max-width: 575.98px) {
    .form-container {
        justify-content: center;
        margin: 0%;
    }
 }
